/* eslint-disable */
import React from 'react'
import TermsAndPrivacyInner from "../TermsAndPrivacyInner/TermsAndPrivacyInner"
import commonClasses from './../TermsAndPrivacyInner/TermsAndPrivacyCommonClasses.module.scss'


const PrivacyPolicy = () => {
    return (
        <TermsAndPrivacyInner title={'Privacy Policy'}>
            <PrivacyPolicyComponent/>
        </TermsAndPrivacyInner>
    )
}


const PrivacyPolicyComponent = () => {
    return (
        <div className={`${commonClasses.container}`} style={{
            marginTop: '40px'
        }}>
            <div>
                <h3 className={commonClasses.commonTitle}>Introduction</h3>
                <p className={commonClasses.commonText}>Welcome to CaseInsight.Net Privacy Policy!</p>
                <p className={commonClasses.commonText}>
                    CaseInsight.Net ("we" or "us" or "our") respects the privacy of our users ("user" or "you"). This
                    Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit
                    our
                    web application, including any other media form, media channel, mobile website, or mobile
                    application
                    related or connected thereto (collectively, the "Site"). Please read this Privacy Policy carefully.
                    IF
                    YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE SITE.
                </p>
                <p className={commonClasses.commonText}>
                    We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will
                    alert you about any changes by updating the "Last Updated" date of this Privacy Policy. You are
                    encouraged to periodically review this Privacy Policy to stay informed of updates. You will be
                    deemed to
                    have been made aware of, will be subject to, and will be deemed to have accepted the changes in any
                    revised Privacy Policy by your continued use of the Site after the date such revised Privacy Policy
                    is
                    posted.
                </p>
            </div>
            <div>
                <h3 className={commonClasses.commonTitle}>Collection of your Information</h3>
                <p className={commonClasses.commonText}>
                    We may collect information about you in a variety of ways. The information we may collect on the
                    Site includes:
                </p>
                <p className={commonClasses.commonText}>- Personal Data</p>
                <p className={commonClasses.commonText}>- Sensitive Data</p>
            </div>
            <div>
                <h3 className={commonClasses.commonTitle}>Use of your Information</h3>
                <p className={commonClasses.commonText}>We use your information to:</p>
                <p className={commonClasses.commonText}>- Facilitate account creation and logon process.</p>
                <p className={commonClasses.commonText}>- Email you regarding your account or order.</p>
                <p className={commonClasses.commonText}>- Notify you of updates to the Site.</p>
                <p className={commonClasses.commonText}>- Offer new products, services, and/or recommendations to
                    you.</p>
            </div>
            <div>
                <h3 className={commonClasses.commonTitle}>Security of your Information</h3>
                <p className={commonClasses.commonText}>
                    We use administrative, technical, and physical security measures to help protect your personal
                    information. While we have taken reasonable steps to secure the personal information you provide to
                    us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and
                    no method of data transmission can be guaranteed against any interception or other type of misuse.
                    Any information disclosed online is vulnerable to interception and misuse by unauthorized parties.
                    Therefore, we cannot guarantee complete security if you provide personal information.
                </p>
            </div>
            <div>
                <h3 className={commonClasses.commonTitle}>Deletion of Your Personal Data</h3>
                <p className={commonClasses.commonText}>
                    You have the right to request the deletion of Personal Data that We have collected about You. However, please note that the deletion of accounts or any associated Personal Data can only be carried out by the administrator of our Service.
                </p>
                <p className={commonClasses.commonText}>
                    To request the deletion of Your account or any Personal Data, please contact Us directly. We will process Your request in accordance with applicable laws and aim to fulfill it as promptly as possible.
                </p>
            </div>
            <div>
                <h3 className={commonClasses.commonTitle}>Retention of Personal Data</h3>
                <p className={commonClasses.commonText}>
                    While we make every effort to honor Your deletion requests, certain information may need to be retained to comply with legal obligations, resolve disputes, enforce our policies, or for other lawful purposes.
                </p>
            </div>
            <div>
                <h3 className={commonClasses.commonTitle}>Contact Us</h3>
                <p className={commonClasses.commonText}>
                    If you have questions or comments about this Privacy Policy, please contact us at:
                </p>
                <div className={commonClasses.email}>
                    <span>DDr. Karl Golling</span>
                    <a href="mailto:karlgolling@gmail.com">karlgolling@gmail.com</a>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
