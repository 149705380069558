import axios from './../helpers/axiosHelper'
import { CurrentUserApiType, DiagnosisApiType, DreamInterpreterApiType, InstanceApiType, PersonApiType, ReferenceApiType, RequestApiType, SessionApiType, UserApiType} from '../types/apiTypes'

export const currentUserAPI: CurrentUserApiType = {
  signIn(loginData) {
    return axios.post('/login', loginData)
  },
  signUp(signUpData) {
    return axios.post('/users/sign-up', signUpData)
  },
  getUserReportSettings() {
    return axios.get(`/users/report-settings`)
  },
  getUserReportSettingsByUserId(userId) {
    return axios.get(`/users/${userId}/report-settings`)
  },
  updateUserReportSettings(data) {
    return axios.put(`/users/report-settings`, data)
  },
  getUIPermissions() {
    return axios.get(`/permissions/ui`)
  },
  updateUserParameters(userId, data) {
    return axios.put(`/users/${userId}/parameters`, data)
  },
  updateUserMainData(userId, data) {
    return axios.put(`/users/${userId}/change-current`, data)
  }
}

export const userAPI: UserApiType = {
  getUserList(requestParams) {
    return axios.post(`/users/filter`, requestParams)
  },
  getUserById(userId) {
    return axios.get(`/users/${userId}`)
  },
  createUser(formData) {
    return axios.post(`/users`, formData)
  },
  editUser(id, formData) {
    return axios.put(`/users/${id}`, formData)
  },
  deleteUser(id) {
    return axios.delete(`/users/${id}`)
  },
  getUserRoleList() {
    return axios.get(`/roles`)
  },
  getUserTokens(userId) {
    return axios.post(`/chat-gpt/tokens-number`, {user_id: userId})
  },
  getUserBillingSettings(user_id){
    return axios.get(`/users/${user_id}/settings/billing`)
  },
  changeAccountBalance(reqData){
    return axios.post(`/account-arrays/change-balance`, reqData)
  },
  editUserActiveStatus(userId, isActive){
    return axios.put(`/users/${userId}/change-active`, isActive)
  },
  getUserListForSuperUser(reqData){
    return axios.post(`/users/select`, reqData)
  },
  getUserTransactions(reqData){
    return axios.post(`/account-arrays/transactions`, reqData)
  },
  getUserDefaultSettings() {
    return axios.get(`/app-params/default`)
  },
  confirmUserRegistration(user_id: number, is_confirmed: boolean){
    return axios.post(`/users/confirm`, {
      user_id,
      is_confirmed
    })
  },
  setUserPassword(password: string, token: string){
    return axios.post(`/login/set-password`, {
      password,
      token
    })
  },
  restorePassword(email: string) {
    return axios.post(`/login/restore-password`, {email})
  },
  updateUserAccess(userId, isAllowedContinue) {
    return axios.put(`/users/${userId}/change-is-allowed-continue`, {is_allowed_continue: isAllowedContinue})
  },
}

export const personAPI: PersonApiType = {
  getPersonList(requestParams) {
    return axios.post(`/persons/filter`, requestParams)
  },
  getPersonById(id) {
    return axios.get(`/persons/${id}`)
  },
  createPerson(formData) {
    return axios.post(`/persons`, formData)
  },
  editPerson(id, formData) {
    return axios.put(`/persons/${id}`, formData)
  },
  deletePerson(id) {
    return axios.delete(`/persons/${id}`)
  },
  getPersonReportSettings(id) {
    return axios.get(`/persons/${id}/report-settings`)
  },
  editPersonActiveStatus(id, isActive) {
    return axios.put(`/persons/${id}/change-active`, isActive)
  },
}

export const instanceAPI: InstanceApiType = {
  getInstanceList(requestData) {
    return axios.post(`/instances/filter`, requestData)
  },
  getInstanceTopicList(instanceId) {
    return axios.get(`/instances/${instanceId}/topics`)
  },
  getInstanceSubtopicList(topicId) {
    return axios.get(`/instance-topics/${topicId}/subtopics`)
  },
  getInstanceById(instanceId) {
    return axios.get(`/instances/${instanceId}`)
  },
  createInstance(data) {
    return axios.post(`/instances`, data)
  },
  createInstanceTopic(data) {
    return axios.post(`/instance-topics`, data)
  },
  createInstanceSubtopic(data) {
    return axios.post(`/instance-subtopics`, data)
  },
  editInstance(instanceId, data) {
    return axios.put(`/instances/${instanceId}`, data)
  },
  editInstanceTopic(topicId, data) {
    return axios.put(`/instance-topics/${topicId}`, data)
  },
  editInstanceSubtopic(subtopicId, data) {
    return axios.put(`/instance-subtopics/${subtopicId}`, data)
  },
  deleteInstance(instanceId) {
    return axios.delete(`/instances/${instanceId}`)
  },
  deleteInstanceTopics(topicIds) {
    return axios.delete(
      `/instance-topics/list`, 
      {
        data: topicIds
      }
    )
  },
  deleteInstanceSubtopics(subtopicIds) {
    return axios.delete(
      `/instance-subtopics/list`,
      {
        data: subtopicIds
      }
    )
  },
  reorderInstances(reorderedList) {
    return axios.put(`/instances/order`, reorderedList)
  },
  getSubtopicCommentHints(subtopicId, userId) {
    return axios.get(`/comment-hints?subtopic_id=${subtopicId}&user_id=${userId}`)
  },
}

export const referenceAPI: ReferenceApiType = {
  getStyleReferences() {
    return axios.get('/references/styles')
  },
  getLanguageReferences() {
    return axios.get('/references/languages')
  },
  getFormatReferences() {
    return axios.get('/references/format')
  },
  getTherapyMethods() {
    return axios.get(`/therapy-method`)
  }
}


export const requestAPI: RequestApiType = {
  createRequest(data) {
    return axios.post(`/requests`,data)
  },
  updateRequest(requestId, data) {
    return axios.put(`/requests/${requestId}`, data)
  },
  confirmRequest(requestId, isConfirmed) {
    return axios.patch(`/requests/${requestId}`, isConfirmed)
  },
  deleteRequest(requestId) {
    return axios.delete(`/requests/${requestId}`)
  },
  makePrompt(requestId, reportType) {
    return axios.post(`requests/${requestId}/make-prompt`, {rep_cd: reportType})
  },
  updatePrompt(requestId, promptData) {
    return axios.put(`/requests/${requestId}/update-prompt`, promptData)
  },
  getRequestListByPersonId(requestData) {
    return axios.post(`/requests/filter`, requestData)
  },
  saveRequestResult(requestId, reportData) {
    return axios.post(`/requests/${requestId}/result`, reportData)
  },
  saveWGKKRequestResult(requestId, reportData) {
    return axios.put(`/requests/${requestId}/params`, reportData)
  },
}

export const sessionAPI: SessionApiType = {
  getSessionsByPersonId(requestData) {
    return axios.post(`/persons-data/filter`, requestData)
  },
  getSessionsBySessionId(sessionId) {
    return axios.get(`/persons-data/${sessionId}`)
  },
  createSession(sessionData) {
    return axios.post(`/persons-data`, sessionData)
  },
  editSession(sessionId, updatedData) {
    return axios.put(`/persons-data/${sessionId}`, updatedData)
  },
  addSessionData(sessionData) {
    return axios.post(`/persons-data-rec/list`, sessionData)
  },
  editSessionData(sessionId, updatedData) {
    return axios.put(`/persons-data-rec/persons-data/${sessionId}`, updatedData)
  },
  deleteSession(sessionId) {
    return axios.delete(`/persons-data/${sessionId}`)
  },
  exportSessionExcel(requestData) {
    return axios.post(`/persons-data/excel`, requestData, {responseType: 'blob'})
  },
  getLastSessionsDateRange(personId, sessionCount) {
    return axios.get(`/persons-data/persons/${personId}/last-sessions${sessionCount ? '?session-count=' + sessionCount : ''}`)
  }
}

export const diagnosisAPI: DiagnosisApiType = {
  getDiagnosis(requestId) {
    return axios.get(`/requests/${requestId}/icd`)
  },
  saveDiagnosisToRequest(requestId, diagnosisText) {
    return axios.post(`/requests/${requestId}/icd`, {message: diagnosisText})
  }
}

export const dreamInterpreterAPI: DreamInterpreterApiType = {
  startChat(perspective: string) {
    return axios.post(`/free-chat/start`, {
      perspective: perspective})
  },
  stopChat(freeChatId) {
    return axios.post(`/free-chat/${freeChatId}/stop`)
  },
  getLastActiveChat() {
    return axios.get('/free-chat/last-active')
  },
  sendMessage(freeChatId, message) {
    return axios.post(`/free-chat/${freeChatId}/send-message`, { message })
  }
}
