import InstanceForm from '../components/Instances/InstanceForm/InstanceForm'
import InstanceList from '../components/Instances/InstanceList'
import PersonForm from '../components/Person/PersonForm/PersonForm'
import PersonList from '../components/Person/PersonList'
import GenerateReport from '../components/Report/GenerateReport/GenerateReport'
import SessionForm from '../components/Sessions/SessionForm/SessionForm'
import SignIn from '../components/SignIn/SignIn'
import UserForm from '../components/User/UserForm/UserForm'
import UserList from '../components/User/UserList'
import Faq from '../components/FAQ/Faq'
import Settings from '../components/Billing/Billing'
import Parameters from '../components/Parameters/Parameters'
import Landing from "../components/Landing/Landing"
import SignUp from "../components/SignUp/SignUp"
import PrivacyPolicy from "../components/Privacy Policy/Privacy Policy"
import Terms from "../components/Terms/Terms"
import CreatePassword from "../components/CreatePassword/CreatePassword"
import RestorePassword from "../components/RestorePassword/RestorePassword"
import LogOutPage from '../components/common/LogOutPage/LogOutPage'

const routes: RouteType[] = [
  {path: '/patients', component: PersonList, auth: true, breadcrumb: 'Patient list'},
  {path: '/patients/add', component: PersonForm, auth: true, breadcrumb: 'Add patient'},
  {path: '/patients/edit/:id', component: PersonForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit patient'},
  {path: '/patients/:id/sessions/add', component: SessionForm, auth: true, breadcrumb: 'Add session'},
  {path: '/patients/:id/sessions/edit/:sessionId', component: SessionForm,  props: {isEditing: true}, auth: true, breadcrumb: 'Edit session'},
  {path: '/patients/:id/generate-report', component: GenerateReport, auth: true, breadcrumb: 'Add report'},
  {path: '/users', component: UserList, auth: true, breadcrumb: 'User list'},
  {path: '/users/add', component: UserForm, auth: true, breadcrumb: 'Add user'},
  {path: '/users/edit/:id', component: UserForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit user'},
  {path: '/parameters', component: Parameters, auth: true, breadcrumb: 'Parameters'},
  {path: '/instances', component: InstanceList, auth: true, breadcrumb: 'Instances'},
  {path: '/instances/add', component: InstanceForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add instance'},
  {path: '/instances/edit/:id', component: InstanceForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit instance'},
  {path: '/sign-in', component: SignIn, auth: false, breadcrumb: null},
  {path: '/sign-up', component: SignUp, auth: false, breadcrumb: null},
  {path: '/login/create-password/:token?', component: CreatePassword, auth: false, breadcrumb: null},
  {path: '/faq', component: Faq, auth: true, breadcrumb: 'Instances'},
  {path: '/terms', component: Terms, auth: false},
  {path: '/privacyPolicy', component: PrivacyPolicy, auth: false},
  {path: '/settings', component: Settings, auth: true, breadcrumb: 'Settings'},
  {path: '/', component: Landing, auth: false, breadcrumb: null},
  {path: '/signIn', component: SignIn, auth: false, breadcrumb: null},
  {path: '/restore-password', component: RestorePassword, auth: false, breadcrumb: null},
  {path: '/logout', component: LogOutPage, auth: true, breadcrumb: null},
]

interface RouteType {
  path: string
  component: React.FC<any>
  auth: boolean
  props?: object
  breadcrumb?: string | null
}

export default routes
