import { useEffect } from 'react'
import { Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectIsLoggedIn, SignOutThunk } from '../../../store/currentUserReducer'
import { Navigate } from 'react-router-dom'

const LogOutPage = () => {
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(SignOutThunk())
    }
  }, [isLoggedIn, dispatch])

  return isLoggedIn ? ( <Spin style={{width: '100%'}} size='large'/> ) : ( <Navigate to='/sign-in'/> )
}

export default LogOutPage
