import { orderBy } from "lodash"
import { ManualPaginationType } from "../types/appTypes"
import dayjs from "dayjs"

export const getFilteredData = (filterItems: any[], rowsData: any[]):any => {
  let updatedRowsData = rowsData
  filterItems.forEach((filter) => {
    if (!filter.value) return
    const { field, operator, value } = filter
    updatedRowsData = rowsData.filter((row) => {
      const key = field as keyof typeof row
      const cellValue = dayjs(row?.[key], 'YYYY-MM-DDthh:mm:ss').isValid()
        ? dayjs(row?.[key]).format('DD.MM.YYYY')
        : String(row?.[key]).toLowerCase()
      const valueFormatted = operator === 'isAnyOf' ? value : String(value).toLowerCase()
      switch (operator) {
        case 'contains':
          return cellValue?.includes(valueFormatted)
        case 'equals':
          return cellValue === valueFormatted
        case 'startsWith':
          return cellValue?.startsWith(valueFormatted)
        case 'endsWith':
          return cellValue?.endsWith(valueFormatted)
        case 'isEmpty':
          return !cellValue
        case 'isNotEmpty':
          return !!cellValue
        case 'notEquals':
          return cellValue !== valueFormatted
        case 'isAnyOf':
          return valueFormatted?.includes(cellValue)
        default:
          return false
      }
    })
  })
  return updatedRowsData
}

export const getPaginatedData = (dataArray: any[], pageNumber: number, pageSize: number) => {
  const startIndex = (pageNumber - 1) * pageSize
  const endIndex = startIndex + pageSize
  const currentPageData = dataArray.slice(startIndex, endIndex)
  return currentPageData
}

export const getDisplayData = <DataType>(data: DataType[], manualParams: ManualPaginationType, keysForSortWithDefaultType?: string[]): {data: DataType[], totalCount:number} => {
  let updatedData = data || []
  if (!!manualParams?.search?.query?.length) {
    updatedData = updatedData.filter(item => {
      const fieldValues = manualParams?.search?.fields?.map(field => String(item[field as keyof typeof item]).toLowerCase())
      return fieldValues?.some(val => val.includes(manualParams?.search?.query!?.toLowerCase()))
    })
  }
  if (manualParams?.sort?.length) {
    const key = manualParams.sort[0].field as keyof typeof updatedData[0]
    updatedData = orderBy(updatedData, [item => keysForSortWithDefaultType?.includes(key as string) ? item?.[key] : String(item?.[key] === null ? '' : item?.[key])?.toLowerCase()],[manualParams.sort[0].sort?.toLowerCase() as 'asc' | 'desc'])
  }
  if (manualParams?.filters?.items?.length) {
    updatedData = getFilteredData(manualParams?.filters.items, updatedData)
  }
  const paginatedData = getPaginatedData(updatedData, manualParams.page, manualParams.size)
  return ({data: paginatedData, totalCount: updatedData.length})
}
